import type { SubscriptionName } from '@investorean/types';
import styled from 'styled-components';
import { Drawer, Nav } from 'rsuite';
import { Menu } from '@rsuite/icons';

import { mq } from '../../styles/mediaQueries';
import GatsbyLink from '../../common/Link/GatsbyLink';
import { InvestoreanLink } from '../../styles/fragments';
import { DropdownMenu } from '../../styles/common';

const headerHeight = '70px';

export const HeaderNavbar = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 100;
  width: 100vw;
  height: ${headerHeight};
  padding: 0 40px;
  background: var(--rs-body);
  border-bottom: 1px solid var(--rs-border-primary);
  box-shadow: rgb(4 1 19 / 5%) 6px 6px 54px;
  gap: 20px;

  @media screen and (${mq.desktopLargeMax}) {
    padding: 0 40px;
  }

  @media screen and (${mq.mobile}) {
    padding: 0 20px;
  }
`;

export const HeaderNavDesktop = styled.nav`
  display: flex;
  align-items: center;
  gap: 40px;

  a {
    font-size: 1.2em;
    font-weight: 600;
  }
`;

export const HeaderNavMobile = styled.nav<{ $plan?: SubscriptionName }>`
  display: flex;
  align-items: center;
  position: relative;
  color: var(--i-link-primary);

  a {
    display: block;
    padding: 0 20px;
    margin-right: 20px;
  }

  ${({ $plan }) => $plan && getPremiumUserBadge($plan)}
`;

export const HeaderLogo = styled.img`
  display: block;
  width: 167px;

  @media screen and (${mq.mobile}) {
    max-width: 140px;
  }
`;

export const DropdownLinkContainer = styled.div`
  display: block;
  position: relative;
  white-space: nowrap;
`;

export const HeaderHomeLink = styled((props) => <GatsbyLink {...props} />)`
  ${InvestoreanLink}
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  font-size: 1.8em;

  .investorean-logo {
    height: 25px;

    .letter-i {
      fill: var(--i-link-primary) !important;
    }
  }
`;

export const HeaderLink = styled((props) => <GatsbyLink {...props} />)<{
  $active?: number;
}>`
  color: ${({ $active }) =>
    $active ? 'var(--i-primary-light)' : 'var(--i-link-primary)'};

  &:hover {
    color: var(--i-primary-light);
  }

  &.dropdown {
    &:hover + ${DropdownMenu} {
      opacity: 1;
      transform: rotateX(0);
      visibility: visible;
    }
  }
`;

export const HeaderExternalLink = styled.a<{ $active?: number }>`
  color: ${({ $active }) =>
    $active ? 'var(--i-primary-light)' : 'var(--i-link-primary)'};

  &:hover {
    color: var(--i-primary-light);
  }

  &.dropdown {
    &:hover + ${DropdownMenu} {
      opacity: 1;
      transform: rotateX(0);
      visibility: visible;
    }
  }
`;

export const getPremiumUserBadge = (plan: SubscriptionName) => `
&::after {
  content: '⛤';
  position: absolute;
  color: ${plan === 'Pro' || plan === 'Premium' ? 'var(--rs-yellow-500)' : 'var(--rs-blue-500)'};
  width: 20px;
  height: 20px;
  top: -10px;
  right: -20px;
  font-size: 20px;
  line-height: 20px;
  text-align: center;

  @media screen and (${mq.mobile}) {
    right: 15px;
  }
}
`;

export const UserNameDesktop = styled.div<{ $plan?: SubscriptionName }>`
  display: inline-block;
  font-size: 1.2em;
  font-weight: bold;
  position: relative;
  color: var(--i-link-primary);

  &:hover {
    cursor: pointer;
  }

  &:hover + ${DropdownMenu} {
    opacity: 1;
    transform: rotateX(0);
    visibility: visible;
  }

  svg {
    width: 11px;
  }

  ${({ $plan }) => $plan && getPremiumUserBadge($plan)}
`;

export const UserNameMobile = styled.div`
  position: relative;
  font-size: 16px;
  font-weight: 600;
  text-align: right;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media screen and (${mq.tablet}) {
    max-width: calc(100vw - 360px);
  }

  @media screen and (${mq.mobile}) {
    max-width: calc(100vw - 150px);
  }
`;

export const MobileMenuDrawer = styled(Drawer)`
  .rs-drawer-header {
    display: flex;
    flex-direction: row-reverse;
    justify-content: stretch;
    align-items: center;
    padding: 15px 20px 15px 60px;

    .logo {
      ${InvestoreanLink}
      text-transform: uppercase;
      font-size: 1.8em;
      line-height: 2em;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
    }

    .investorean-logo {
      height: 25px;

      .letter-i {
        fill: var(--i-link-primary) !important;
      }
    }

    .rs-drawer-header-close {
      display: block;
      position: relative;
      left: auto;
      top: auto;
      margin-left: auto;

      .rs-icon {
        font-size: 22px;
      }
    }
  }

  .rs-drawer-body {
    padding-top: 0;
  }
`;

export const MobileMenuNav = styled(Nav)`
  .rs-nav-item {
    padding: 0 0 30px 0;
    border-bottom: 1px solid var(--rs-border-primary);
  }
`;

export const MobileMenuIcon = styled(Menu)`
  font-size: 16px;
  margin-left: 10px;
`;

export const MobileMenuLink = styled((props) => <GatsbyLink {...props} />)<{
  $active?: number;
}>`
  font-size: 18px;
  line-height: 20px;
  display: block;
  padding: 20px 0;
  color: ${({ $active }) =>
    $active ? 'var(--i-primary-light)' : 'var(--rs-primary-text)'};

  &:not(:last-of-type) {
    border-bottom: 1px solid var(--rs-border-primary);
  }
`;

export const MobileMenuExternalLink = styled.a<{ $active?: number }>`
  font-size: 18px;
  line-height: 20px;
  display: block;
  padding: 20px 0;
  color: ${({ $active }) =>
    $active ? 'var(--i-primary-light)' : 'var(--rs-primary-text)'};

  &:not(:last-of-type) {
    border-bottom: 1px solid var(--rs-border-primary);
  }
`;
