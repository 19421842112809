import { type FC, useEffect } from 'react';
import type { PageProps } from 'gatsby';
import { CustomProvider } from 'rsuite';

import { Header } from '../Header';
import { Footer } from '../Footer';
import IncidentMessage from '../IncidentMessage';
import { env } from '../../utils/env';
import { MainLayoutContainer } from '../../styles/layout';
import { useTheme } from '../../context/theme';
import Autoslider from '../Autosliider';
import BrokerPanel from '../BrokerPanel';
import Offline from '../../common/Offline';
import useConnectionStatus from '../../hooks/useConnectionStatus';

declare global {
  interface Window {
    $crisp: unknown[];
    CRISP_WEBSITE_ID?: string;
  }
}

const HEADER_EXCLUDED_PATHS: string[] = ['/'];
const BROKER_PANEL_EXCLUDED_PATHS: string[] = [
  '/',
  '/login/',
  '/pricing/',
  '/settings/',
  '/faq/',
  '/contact/',
  '/terms/',
  '/privacy/',
];
const AUTOSLIDER_EXCLUDED_PATHS: string[] = [
  '/',
  '/login/',
  '/pricing/',
  '/settings/',
  '/faq/',
  '/contact/',
  '/terms/',
  '/privacy/',
];

const MainLayout: FC<PageProps> = ({ children, location }) => {
  const { theme } = useTheme();
  const { online } = useConnectionStatus();

  useEffect(() => {
    window.feedspace?.init();
    window.$crisp = [];
    window.CRISP_WEBSITE_ID = env.crispWebsiteId;
  }, []);

  if (!online) {
    return <Offline />;
  }

  return (
    <MainLayoutContainer>
      <IncidentMessage />
      {!HEADER_EXCLUDED_PATHS.includes(location.pathname) && <Header />}
      {!AUTOSLIDER_EXCLUDED_PATHS.includes(location.pathname) && <Autoslider />}
      {!BROKER_PANEL_EXCLUDED_PATHS.includes(location.pathname) && (
        <BrokerPanel />
      )}
      <CustomProvider theme={theme}>{children}</CustomProvider>
      <Footer />
    </MainLayoutContainer>
  );
};

export default MainLayout;
