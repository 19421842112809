import type { FC } from 'react';
import type { WrapRootElementBrowserArgs } from 'gatsby';
import {
  browserTracingIntegration,
  init as initSentry,
  replayIntegration,
} from '@sentry/gatsby';
import { getApp } from 'firebase/app';
import type { AppCheck } from 'firebase/app-check';
import 'firebase/functions';
import 'firebase/remote-config';
import 'firebase/storage';

import { FirebaseProvider } from './firebase';
import { isBrowser, isDevelopment } from '../utils/env';
import { ThemeContextProvider } from './theme';
import { ThemeColorsDark, ThemeColorsLight } from '../styles/colors';
import {
  createFirebaseApp,
  createFirebaseAppCheck,
  createFirebaseAuth,
  createFirebaseFirestore,
  firebaseConfig,
} from '../utils/firebase';
import ErrorBoundary from '../common/ErrorBoundary';
import { BrokerContextProvider } from './broker';

const CONSOLE_MESSAGE = `
=========================================================================================

██ ███    ██ ██    ██ ███████ ███████ ████████  ██████  ██████  ███████  █████  ███    ██
██ ████   ██ ██    ██ ██      ██         ██    ██    ██ ██   ██ ██      ██   ██ ████   ██
██ ██ ██  ██ ██    ██ █████   ███████    ██    ██    ██ ██████  █████   ███████ ██ ██  ██
██ ██  ██ ██  ██  ██  ██           ██    ██    ██    ██ ██   ██ ██      ██   ██ ██  ██ ██
██ ██   ████   ████   ███████ ███████    ██     ██████  ██   ██ ███████ ██   ██ ██   ████

=========================================================================================

If you found a bug or inaccuracy in our data, we kindly ask you to report it here -> https://investorean.com/contact

`;

createFirebaseApp(firebaseConfig);

export let appCheck: AppCheck | null = null;

if (isBrowser()) {
  if (isDevelopment() && typeof self !== 'undefined') {
    // @ts-ignore
    self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
  }
  appCheck = createFirebaseAppCheck(getApp());
  createFirebaseAuth(getApp());
  createFirebaseFirestore(getApp());
  if (process.env.GATSBY_SENTRY_DSN) {
    initSentry({
      dsn: process.env.GATSBY_SENTRY_DSN,
      environment: process.env.GATSBY_ENV,
      release: process.env.GATSBY_GITHUB_SHA,
      integrations: [browserTracingIntegration(), replayIntegration()],
      tracesSampleRate: 0.5,
      replaysSessionSampleRate: 0.5,
      replaysOnErrorSampleRate: 1.0,
    });
  }
}

export const wrapRootElement: FC<WrapRootElementBrowserArgs> = ({
  element,
}) => {
  if (isBrowser()) {
    console.info(CONSOLE_MESSAGE);
  }

  return (
    <FirebaseProvider appCheck={appCheck}>
      <ErrorBoundary>
        <ThemeContextProvider>
          <ThemeColorsLight />
          <ThemeColorsDark />
          <BrokerContextProvider>{element}</BrokerContextProvider>
        </ThemeContextProvider>
      </ErrorBoundary>
    </FirebaseProvider>
  );
};
