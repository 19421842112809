import {
  initializeApp,
  getApp,
  type FirebaseOptions,
  type FirebaseApp,
  getApps,
} from 'firebase/app';
import {
  initializeAppCheck,
  ReCaptchaEnterpriseProvider,
} from 'firebase/app-check';
import {
  browserLocalPersistence,
  browserPopupRedirectResolver,
  getAuth,
  indexedDBLocalPersistence,
  initializeAuth,
} from 'firebase/auth';
import { getFirestore, initializeFirestore } from 'firebase/firestore';
import { env } from './env';

export const firebaseConfig: FirebaseOptions = {
  apiKey: process.env.GATSBY_FIREBASE_API_KEY,
  authDomain: process.env.GATSBY_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.GATSBY_FIREBASE_DATABASE_URL,
  projectId: process.env.GATSBY_FIREBASE_PROJECT_ID,
  storageBucket: process.env.GATSBY_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.GATSBY_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.GATSBY_FIREBASE_APP_ID,
  measurementId: process.env.GATSBY_FIREBASE_MEASUREMENT_ID,
};

export const createFirebaseApp = (config = {}): FirebaseApp => {
  return getApps().length ? getApp() : initializeApp(config);
};

export const createFirebaseAuth = (app: FirebaseApp) => {
  return getApps().length
    ? getAuth()
    : initializeAuth(app, {
        persistence: [indexedDBLocalPersistence, browserLocalPersistence],
        popupRedirectResolver: browserPopupRedirectResolver,
      });
};

export const createFirebaseFirestore = (app: FirebaseApp) => {
  return getApps().length
    ? getFirestore()
    : initializeFirestore(app, {
        ignoreUndefinedProperties: true,
      });
};

export const createFirebaseAppCheck = (app: FirebaseApp) => {
  return initializeAppCheck(getApp(), {
    provider: new ReCaptchaEnterpriseProvider(env.recaptchaSiteKey),
    isTokenAutoRefreshEnabled: true,
  });
};
