import { type FC, useCallback } from 'react';
import { useLocation } from '@reach/router';
import Down from '@rsuite/icons/legacy/Down';
import Link from '../../common/Link';
import type { NavBarDesktopProps } from './types';
import { useFirebase } from '../../context/firebase';

import {
  HeaderLink,
  HeaderNavDesktop,
  UserNameDesktop,
  DropdownLinkContainer,
  HeaderExternalLink,
} from './styles';
import { INTERNAL_URIS } from '../../constants/navigation';
import { DropdownMenu } from '../../styles/common';

export const NavBarDesktop: FC<NavBarDesktopProps> = ({ user }) => {
  const location = useLocation();
  const { userPlan } = useFirebase();
  const renderUsername = useCallback(() => {
    if (!user?.name) {
      return '';
    }

    if (user.name.length > 15) {
      return `${user.name.slice(0, 15)}...`;
    }

    return user.name;
  }, [user]);

  return (
    <HeaderNavDesktop>
      <DropdownLinkContainer>
        <HeaderLink
          to={INTERNAL_URIS.explore.path}
          title={INTERNAL_URIS.explore.title}
          className="dropdown"
        >
          Markets
          <Down height={12} style={{ marginLeft: '5px' }} />
        </HeaderLink>
        <DropdownMenu>
          <HeaderLink
            to={INTERNAL_URIS.stocksScreener.path}
            title={INTERNAL_URIS.stocksScreener.title}
            $active={
              location.pathname.startsWith(INTERNAL_URIS.stock.path) ? 1 : 0
            }
          >
            Stocks
          </HeaderLink>
          <HeaderLink
            to={INTERNAL_URIS.etfsScreener.path}
            title={INTERNAL_URIS.etfsScreener.title}
            $active={
              location.pathname.startsWith(INTERNAL_URIS.etf.path) ? 1 : 0
            }
          >
            ETFs
          </HeaderLink>
          <HeaderLink
            to={INTERNAL_URIS.indicesScreener.path}
            title={INTERNAL_URIS.indicesScreener.title}
            $active={
              location.pathname.startsWith(INTERNAL_URIS.index.path) ? 1 : 0
            }
          >
            Indices
          </HeaderLink>
          <HeaderLink
            to={INTERNAL_URIS.currenciesScreener.path}
            title={INTERNAL_URIS.currenciesScreener.title}
            $active={
              location.pathname.startsWith(INTERNAL_URIS.currency.path) ? 1 : 0
            }
          >
            Currencies
          </HeaderLink>
        </DropdownMenu>
      </DropdownLinkContainer>
      <DropdownLinkContainer>
        <HeaderLink
          to={INTERNAL_URIS.dashboard.path}
          title={INTERNAL_URIS.dashboard.title}
          className="dropdown"
        >
          Trading Floor
          <Down height={12} style={{ marginLeft: '5px' }} />
        </HeaderLink>
        <DropdownMenu>
          <HeaderLink
            to={INTERNAL_URIS.dashboard.path}
            $active={
              location.pathname.startsWith(INTERNAL_URIS.dashboard.path) ? 1 : 0
            }
            title={INTERNAL_URIS.dashboard.title}
          >
            Dashboard
          </HeaderLink>
          <HeaderLink
            to={INTERNAL_URIS.portfolios.path}
            $active={
              location.pathname.startsWith(INTERNAL_URIS.portfolios.path)
                ? 1
                : 0
            }
            title={INTERNAL_URIS.portfolios.title}
          >
            My Portfolios
          </HeaderLink>
          <HeaderLink
            to={INTERNAL_URIS.watchlists.path}
            $active={
              location.pathname.startsWith(INTERNAL_URIS.watchlists.path)
                ? 1
                : 0
            }
            title={INTERNAL_URIS.watchlists.title}
          >
            My Watchlists
          </HeaderLink>
          <HeaderLink
            to={INTERNAL_URIS.sec13f.path}
            $active={
              location.pathname.startsWith(INTERNAL_URIS.sec13f.path) ? 1 : 0
            }
            title={INTERNAL_URIS.sec13f.title}
          >
            Hedge Funds
          </HeaderLink>
          <HeaderLink
            to={INTERNAL_URIS.senate.path}
            $active={
              location.pathname.startsWith(INTERNAL_URIS.senate.path) ? 1 : 0
            }
            title={INTERNAL_URIS.senate.title}
          >
            U.S. Politician Trades
          </HeaderLink>
          <HeaderLink
            to={INTERNAL_URIS.calendar.path}
            $active={
              location.pathname.startsWith(INTERNAL_URIS.calendar.path) ? 1 : 0
            }
            title={INTERNAL_URIS.calendar.title}
          >
            Economic Calendar
          </HeaderLink>
          <HeaderExternalLink
            href={INTERNAL_URIS.blog.path}
            title={INTERNAL_URIS.blog.title}
            target="_blank"
          >
            Blog
          </HeaderExternalLink>
        </DropdownMenu>
      </DropdownLinkContainer>
      <HeaderLink
        to={INTERNAL_URIS.pricing.path}
        $active={
          location.pathname.startsWith(INTERNAL_URIS.pricing.path) ? 1 : 0
        }
        title={INTERNAL_URIS.pricing.title}
      >
        Pricing
      </HeaderLink>
      {user ? (
        <DropdownLinkContainer>
          <UserNameDesktop $plan={userPlan}>
            {renderUsername()}
            <Down height={12} style={{ marginLeft: '5px' }} />
          </UserNameDesktop>
          <DropdownMenu>
            <HeaderLink
              to={INTERNAL_URIS.settings.path}
              $active={
                location.pathname.startsWith(INTERNAL_URIS.settings.path)
                  ? 1
                  : 0
              }
              title={INTERNAL_URIS.settings.title}
            >
              Settings
            </HeaderLink>
            <HeaderLink
              to={INTERNAL_URIS.logout.path}
              title={INTERNAL_URIS.logout.title}
            >
              Log out
            </HeaderLink>
          </DropdownMenu>
        </DropdownLinkContainer>
      ) : (
        <Link
          to={INTERNAL_URIS.login.path}
          state={{ prevPageHref: location.href }}
          variant="ghost"
          title={INTERNAL_URIS.login.title}
        >
          Log In / Sign Up
        </Link>
      )}
    </HeaderNavDesktop>
  );
};
