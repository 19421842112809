import type { FC } from 'react';
import type { LinkProps } from './types';
import { LinkStyled } from './styles';

const Link: FC<LinkProps> = (props) => {
  const { to, variant, children, active, ...restProps } = props;
  return (
    // @ts-ignore
    <LinkStyled {...restProps} to={to} $variant={variant} $active={!!active}>
      {children}
    </LinkStyled>
  );
};

export default Link;
