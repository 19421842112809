import { type FC, useMemo } from 'react';
import Link from '../../common/Link/GatsbyLink';
import type { AutosliderProps } from './types';
import {
  AutosliderContainer,
  AutosliderItem,
  AutosliderWrapper,
  AutosliderTicker,
  AutosliderPrice,
  AutosliderPriceChange,
} from './styles';
import { TOP_VALUED_ASSETS } from './constants';
import { isBrowser } from '../../utils/env';
import { INTERNAL_URIS } from '../../constants/navigation';
import useAssetsPreview from '../../hooks/useAssetsPreview';

const Autoslider: FC<AutosliderProps> = ({ assets = TOP_VALUED_ASSETS }) => {
  const { items } = useAssetsPreview(assets);

  const section = useMemo(
    () =>
      [...items, ...items].map((item, index) => (
        <AutosliderItem key={`autoslider-item-${index}`}>
          <Link
            to={`${INTERNAL_URIS.stockDetails.path}/${item.symbol}`}
            title={INTERNAL_URIS.stockDetails.title}
          >
            <AutosliderTicker>{item.ticker}</AutosliderTicker>
            <AutosliderPrice>${item.price}</AutosliderPrice>
            <AutosliderPriceChange $value={item.day1ChangePercent || 0}>
              {(item.day1ChangePercent || 0) > 0 ? '+' : ''}
              {item.day1ChangePercent || 0}%
            </AutosliderPriceChange>
          </Link>
        </AutosliderItem>
      )),
    [items],
  );

  const speed = useMemo(() => {
    if (!isBrowser()) {
      return 30000;
    }

    return Math.floor(window.innerWidth * items.length * 3.5);
  }, [items]);

  if (!items.length) {
    return null;
  }

  return (
    <AutosliderContainer>
      <AutosliderWrapper $speed={speed}>{section}</AutosliderWrapper>
    </AutosliderContainer>
  );
};

export default Autoslider;
