import { type FC, useState, type ReactNode } from 'react';
import { type DrawerProps, Drawer } from 'rsuite';
import { useLocation } from '@reach/router';
import { useMediaQuery } from '@react-hook/media-query';
import Link from '../../common/Link';
import { useFirebase } from '../../context/firebase';
import type { NavBarMobileProps } from './types';
import {
  HeaderNavMobile,
  UserNameMobile,
  MobileMenuDrawer,
  MobileMenuIcon,
  MobileMenuNav,
  MobileMenuLink,
  MobileMenuExternalLink,
} from './styles';
import { INTERNAL_URIS } from '../../constants/navigation';
import { ReactComponent as InvestoreanLogo } from '../../../static/investorean-logo-square-transparent.svg';
import { mq } from '../../styles/mediaQueries';

export const MobileMenuPanel: FC<DrawerProps> = (props) => {
  const { user } = useFirebase();
  const location = useLocation();
  const isMobile = useMediaQuery(`only screen and (${mq.mobile})`);

  const renderUserItems = (): ReactNode => {
    if (user) {
      return (
        <>
          <MobileMenuLink
            to={INTERNAL_URIS.dashboard.path}
            title={INTERNAL_URIS.dashboard.title}
            $active={
              location.pathname.startsWith(INTERNAL_URIS.dashboard.path) ? 1 : 0
            }
            onClick={props.onClose}
          >
            Dashboard
          </MobileMenuLink>
          <MobileMenuLink
            to={INTERNAL_URIS.calendar.path}
            title={INTERNAL_URIS.calendar.title}
            $active={
              location.pathname.startsWith(INTERNAL_URIS.calendar.path) ? 1 : 0
            }
            onClick={props.onClose}
          >
            Economic Calendar
          </MobileMenuLink>
          <MobileMenuLink
            to={INTERNAL_URIS.portfolios.path}
            title={INTERNAL_URIS.portfolios.title}
            $active={
              location.pathname.startsWith(INTERNAL_URIS.portfolios.path)
                ? 1
                : 0
            }
            onClick={props.onClose}
          >
            My Portfolios
          </MobileMenuLink>
          <MobileMenuLink
            to={INTERNAL_URIS.watchlists.path}
            title={INTERNAL_URIS.watchlists.title}
            $active={
              location.pathname.startsWith(INTERNAL_URIS.watchlists.path)
                ? 1
                : 0
            }
            onClick={props.onClose}
          >
            My Watchlists
          </MobileMenuLink>
          <MobileMenuLink
            to={INTERNAL_URIS.settings.path}
            title={INTERNAL_URIS.settings.title}
            $active={
              location.pathname.startsWith(INTERNAL_URIS.settings.path) ? 1 : 0
            }
            onClick={props.onClose}
          >
            Settings
          </MobileMenuLink>
          <MobileMenuLink
            to={INTERNAL_URIS.logout.path}
            title={INTERNAL_URIS.logout.title}
          >
            Log out
          </MobileMenuLink>
        </>
      );
    }

    return (
      <>
        <MobileMenuLink
          to={INTERNAL_URIS.login.path}
          state={{ prevPageHref: location.href }}
          title={INTERNAL_URIS.login.title}
          onClick={props.onClose}
        >
          Log In
        </MobileMenuLink>
        <MobileMenuLink
          to={INTERNAL_URIS.login.path}
          state={{ prevPageHref: location.href }}
          title={INTERNAL_URIS.login.title}
          onClick={props.onClose}
        >
          Sign Up
        </MobileMenuLink>
      </>
    );
  };

  return (
    <MobileMenuDrawer {...props} size="full" onClick={props.onClose}>
      <Drawer.Header>
        <Link
          to={INTERNAL_URIS.homepage.path}
          variant="unstyled"
          onClick={props.onClose}
          className="logo"
          title={INTERNAL_URIS.homepage.title}
        >
          <InvestoreanLogo height={26} />
          {!isMobile && <span>Investorean</span>}
        </Link>
      </Drawer.Header>
      <Drawer.Body>
        <MobileMenuNav vertical>
          <MobileMenuLink
            to={INTERNAL_URIS.explore.path}
            title={INTERNAL_URIS.explore.title}
            $active={
              location.pathname.startsWith(INTERNAL_URIS.explore.path) ? 1 : 0
            }
            onClick={props.onClose}
          >
            Explore
          </MobileMenuLink>
          <MobileMenuLink
            to={INTERNAL_URIS.stocksScreener.path}
            title={INTERNAL_URIS.stocksScreener.title}
            $active={
              location.pathname.startsWith(INTERNAL_URIS.stock.path) ? 1 : 0
            }
            onClick={props.onClose}
          >
            Stocks
          </MobileMenuLink>
          <MobileMenuLink
            to={INTERNAL_URIS.etfsScreener.path}
            title={INTERNAL_URIS.etfsScreener.title}
            $active={
              location.pathname.startsWith(INTERNAL_URIS.etf.path) ? 1 : 0
            }
            onClick={props.onClose}
          >
            ETFs
          </MobileMenuLink>
          <MobileMenuLink
            to={INTERNAL_URIS.indicesScreener.path}
            title={INTERNAL_URIS.indicesScreener.title}
            $active={
              location.pathname.startsWith(INTERNAL_URIS.index.path) ? 1 : 0
            }
            onClick={props.onClose}
          >
            Indices
          </MobileMenuLink>
          <MobileMenuLink
            to={INTERNAL_URIS.currenciesScreener.path}
            title={INTERNAL_URIS.currenciesScreener.title}
            $active={
              location.pathname.startsWith(INTERNAL_URIS.currency.path) ? 1 : 0
            }
            onClick={props.onClose}
          >
            Currencies
          </MobileMenuLink>
          <MobileMenuLink
            to={INTERNAL_URIS.sec13f.path}
            title={INTERNAL_URIS.sec13f.title}
            $active={
              location.pathname.startsWith(INTERNAL_URIS.sec13f.path) ? 1 : 0
            }
            onClick={props.onClose}
          >
            Hedge Funds
          </MobileMenuLink>
          <MobileMenuLink
            to={INTERNAL_URIS.senate.path}
            title={INTERNAL_URIS.senate.title}
            $active={
              location.pathname.startsWith(INTERNAL_URIS.senate.path) ? 1 : 0
            }
            onClick={props.onClose}
          >
            U.S. Politician Trades
          </MobileMenuLink>
          <MobileMenuLink
            to={INTERNAL_URIS.pricing.path}
            title={INTERNAL_URIS.pricing.title}
            $active={
              location.pathname.startsWith(INTERNAL_URIS.pricing.path) ? 1 : 0
            }
            onClick={props.onClose}
          >
            Pricing
          </MobileMenuLink>
          <MobileMenuExternalLink
            href={INTERNAL_URIS.blog.path}
            title={INTERNAL_URIS.blog.title}
            target="_blank"
            onClick={props.onClose}
          >
            Blog
          </MobileMenuExternalLink>
          {renderUserItems()}
        </MobileMenuNav>
      </Drawer.Body>
    </MobileMenuDrawer>
  );
};

export const NavBarMobile: FC<NavBarMobileProps> = ({ user }) => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const { userPlan } = useFirebase();
  const location = useLocation();

  return (
    <HeaderNavMobile $plan={userPlan}>
      {user ? (
        <UserNameMobile onClick={() => setIsMenuOpen(true)}>
          {user.name}
        </UserNameMobile>
      ) : (
        <Link
          to={INTERNAL_URIS.login.path}
          title={INTERNAL_URIS.login.title}
          state={{ prevPageHref: location.href }}
          variant="ghost"
        >
          Log In
        </Link>
      )}
      <MobileMenuIcon onClick={() => setIsMenuOpen(true)} />
      <MobileMenuPanel open={isMenuOpen} onClose={() => setIsMenuOpen(false)} />
    </HeaderNavMobile>
  );
};
